<template>
  <div>
    <el-input type="textarea" autosize placeholder="请输入内容" v-model="textarea" id="articleDetails">
    </el-input>
  </div>
</template>
<script>
export default {
  data() {
    return {
      textarea: '',
      sourceID: "",
      id: ""
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    if (this.$route.query.token) {
      this.$cookies.set("TOKEN", this.$route.query.token);
      this.$store.commit("setToken", this.$route.query.token);
    }

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.$route.query.sourceID) {
      this.sourceID = this.$route.query.sourceID;
    }
    this.GetContent();
    // window.onload = () => {
    //   let answer = document.getElementById("articleDetails");
    //   answer.addEventListener("copy", e => {
    //     this.setClipboardText(e);
    //   });
    // }
  },
  methods: {
    GetContent() {
      this.$request({
        url: `/api/proofread/user-center/${this.id}/document-oCRInfo`,
        method: "GET",
        headers: {
          accept: "text/plain",
        },
        params: {},
      }).then((res) => {
        let colon = res.documentListDto.publisher ? ':' : ''
        let comma1 = res.documentListDto.title ? ',' : ''
        let comma2 = res.documentListDto.dateOfPublication ? ',' : ''
        let pages = res.orderBy ? `第${res.orderBy}页` : ''
        const info = "\n" +
            "\n" +
            "---------------------------" +
            "\n" +
            "\n" +
            `${res.documentListDto.publisher} ${colon} ${res.documentListDto.title} ${comma1} ${res.documentListDto.dateOfPublication} ${comma2} ${pages}`
            +
            "\n\n";
        this.textarea = res.content + info
      })
    },
    // // 复制携带版权信息
    // setClipboardText(event) {
    //   event.preventDefault(); //阻止元素发生默认的行为（例如，当点击提交按钮时阻止对表单的提交）。
    //   const node = document.createElement("div");
    //   //对documentfragment不熟，不知道怎么获取里面的内容，用了一个比较笨的方式
    //   node.appendChild(
    //       window
    //           .getSelection()
    //           .getRangeAt(0)
    //           .cloneContents()
    //   );
    //   //getRangeAt(0)返回对基于零的数字索引与传递参数匹配的选择对象中的范围的引用。对于连续选择，参数应为零。
    //   // 本文地址
    //   let articleUrl = window.location.href;
    //   // 网站地址
    //   let websiteUrl = window.location.origin;
    //   let htmlData = "<div>" + node.innerHTML
    //   +"著作权归作者所有。<br />" +
    //   "商业转载请联系作者获得授权，非商业转载请注明出处。<br />" +
    //   "作者：" +
    //   this.documentListDto.contributor +
    //   "<br />链接：" +
    //   articleUrl +
    //   "<br />" +
    //   "来源：" +
    //   websiteUrl +
    //   "<br /><br />" +
    //   "</div>";
    //   let textData =
    //       window.getSelection().getRangeAt(0) +
    //       "\n" +
    //       "\n" +
    //       "---------------------------" +
    //       "\n" +
    //       "\n" +
    //       "著作权归作者所有。\n" +
    //       "商业转载请联系作者获得授权，非商业转载请注明出处。\n" +
    //       "作者：" +
    //       this.documentListDto.contributor +
    //       "\n链接：" +
    //       articleUrl +
    //       "\n" +
    //       "来源：" +
    //       websiteUrl +
    //       "\n\n";
    //   if (event.clipboardData) {
    //     event.clipboardData.setData("text/html", htmlData);
    //     //setData(剪贴板格式, 数据) 给剪贴板赋予指定格式的数据。返回 true 表示操作成功。
    //     event.clipboardData.setData("text/plain", textData);
    //   } else if (window.clipboardData) {
    //     //window.clipboardData的作用是在页面上将需要的东西复制到剪贴板上，提供了对于预定义的剪贴板格式的访问，以便在编辑操作中使用。
    //     return window.clipboardData.setData("text", textData);
    //   }
    // },
  }
}
</script>
